/* Pre login screens */
@value xs from "./breakpoints.module.css";

.preLoginSocial {
  width: 50px;
  height: 50px;
  padding: 8px 4.7px 6px 5.7px;
  border: solid 0.4px #d5d5d5;
  background-color: #ffffff;
  border-radius: 25px;
  cursor: pointer;
}

.primaryInput input {
  height: 54px !important;
  padding: 10px !important;
}

.primaryInput .MuiOutlinedInput-root {
  background: transparent !important;
}

.primaryInput > div {
  background: #fbfbfb !important;
}

.primaryInput > fieldset {
  border: 1px solid #9f9f9f;
}

/* Custom dropdown style */

.dropDown {
  border-radius: 22px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #d5d5d5;
  padding: 9px 15px 10px 0px;
  appearance: none;
  -webkit-appearance: none;
  background: #f6f6f6 url("../assets/images/svg/down-arrow-black.svg") no-repeat
    calc(100% - 15px) center;
  background-size: 13px;
  outline: 0;
  font-size: 14px;
  color: #717171;
  min-width: 137px;
}

.dInlineBlock {
  display: inline-block !important;
}

.grayButton {
  width: 166px;
  height: 40px;
  border: solid 1px #d5d5d5 !important;
  background-color: #f6f6f6 !important;
}

/* Dashboard screens design */

.pageTitle {
  margin: 0 0 20px !important;
}

/* Edit Form */
.borderNone fieldset {
  border: none !important;
}

/* spacing classes */

.my35 {
  margin: 35px 0;
}

.my45 {
  margin: 20px 0;
}

.my30 {
  margin: 30px 0;
}

.my20 {
  margin: 20px 0;
}

.my15 {
  margin: 10px 0;
}

.mb35 {
  margin-bottom: 35px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb10 {
  margin-bottom: 10px;
}

.pb30 {
  padding-bottom: 30px;
}

/* table css  */
.visuallyHidden {
  border: 0px;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20px;
  width: 1px;
}

.tableRoot {
  width: 100%;
  margin: 20px 0;
}

.headerRow {
  background-color: #f2f2f2;
  border-radius: 4px;
}

.headerRow th {
  color: #616161;
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
}

.headerRow th:first-child {
  padding-left: 20px;
}

.tablePaper {
  border: 1px solid #cbcbcb;
  border-radius: 12px !important;
  padding: 30px 23px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

.showTable {
  color: #354052;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-right: 10px !important;
}

.recordBtn {
  width: 140px;
  height: 36px;
  padding: 9px 18.7px 8px 18px !important;
  border-radius: 18px !important;
  border: solid 0.8px #d5d5d5 !important;
  background-color: #f5f5f5 !important;
  font-size: 16px !important;
  color: #717171 !important;
  opacity: 0.9;
  font-weight: 500 !important;
}

/* Common Wrapper Headnig */
.contentHeadingWrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  justify-content: flex-start;
}

.headingLogo {
  width: 70px;
  height: 70px;
  flex: 0 0 70px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: #efefef;
}

.headingLogo img {
  width: 75%;
  height: 75%;
  object-fit: cover;
}

.detailsTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.detailsTitle h2 {
  overflow: hidden;
  font-weight: 700;
  max-width: 700px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.headingIconWrapper {
  cursor: pointer;
  padding: 8px;
  margin: 0 0 0 15px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detailsTitle svg {
  color: #fff;
  font-size: 14px;
}

.verticalLine {
  margin-left: 5px;
  margin-right: 5px;
}

.headingDetails {
  padding-left: 22px;
  word-break: break-all;
}

.headingDetails .contactDescription {
  color: #616161;
  padding-top: 8px;
}

/* Table Icons Design */
.iconsContainer {
  display: flex;
  align-items: center;
}

.iconWrapper {
  cursor: pointer;
  float: left;
  margin-right: 7px;
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconCustom {
  font-size: 17px !important;
}

.blueColorBackground {
  color: rgb(8, 63, 133);
  background-color: #ebf4ff;
}

.darkBlueBackground {
  background-color: #083f85;
}

.greenColorBackground {
  color: #ceffe9;
  background-color: #ceffe9;
}

.redColorBackground {
  color: #ff0000;
  background-color: #ffe4e4;
}

.fwMedium {
  font-weight: 600 !important;
}

.fwBold {
  font-weight: 900 !important;
}

.cursorPointer {
  cursor: pointer;
}

.closeIconWrapper {
  position: absolute;
  top: 14px;
  right: 14px;
  opacity: 0.6;
}

/* table css */
.currentClass {
  min-width: 800px;
}

@media (max-width: 1366px) {
  .detailsTitle h2 {
    max-width: 340px;
  }
}

@media (max-width: 1024px) {
  .detailsTitle h2 {
    max-width: 230px;
  }
}

@media (max-width: 767px) {
  .detailsTitle h2 {
    max-width: 140px;
  }

  .subjectTitle {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-word;
    overflow: hidden;
  }

  .headingIconWrapper {
    padding: 5px;
    margin: 0 0 0 10px;
  }

  .headingLogo {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
  }

  .headingLogo img {
    max-width: 30px;
  }

  .recordBtn {
    width: 80px;
    padding: 5px !important;
    font-size: 12px !important;
  }

  .recordBtn svg {
    width: 15px;
  }

  .headingDetails .contactDescription {
    padding-top: 3px;
  }

  .my45 {
    margin: 25px 0;
  }

  .my35 {
    margin: 20px 0;
  }

  .my30 {
    margin: 15px 0;
  }

  .my20 {
    margin: 10px 0;
  }

  .my15 {
    margin: 10px 0;
  }

  .mb35 {
    margin-bottom: 20px;
  }

  .mb30 {
    margin-bottom: 15px;
  }

  .mb20 {
    margin-bottom: 10px;
  }

  .mb15 {
    margin-bottom: 10px;
  }

  .mb10 {
    margin-bottom: 10px;
  }

  .pb30 {
    padding-bottom: 15px;
  }

  .primaryInput input {
    height: 35px !important;
    padding: 10px !important;
    font-size: 14px !important;
  }

  .iconWrapper {
    display: flex !important;
    width: 25px;
    height: 25px;
  }

  .tablePaper {
    padding: 20px 12px;
  }
}

.animationSpin {
  animation: App-logo-spin infinite 4s linear;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
