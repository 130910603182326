@import "../fonts/stylesheet.css";

* {
  box-sizing: border-box;
}

body,
html {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
}

p {
  font-weight: 400;
}

.subjectTable {
  min-width: 800px;
}
.credential-table {
  min-width: 800px;
}
.issuer-table {
  min-width: 800px;
}
.logs-table {
  min-width: 800px;
}
.draft {
  min-width: 550px;
}

.full-page-loader {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgb(53, 113, 190) 5;
}

.full-page-loader > img {
  animation: 1.8s infinite heartbeat;
}
figure {
  margin: 0;
  padding: 0;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.navlinkWrapper {
  width: 100%;
}
.infinite-scroll-component__outerdiv {
  height: calc(100% + 25px);
}
@media (max-width: 1024px) {
  .navlinkWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .navlinkWrapper > div {
    flex-basis: 48%;
    max-width: 48%;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .navlinkWrapper > div {
    flex-basis: 100%;
    max-width: 100%;
  }
}
